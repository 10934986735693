import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

import { computed, ComputedRef, onMounted, ref, Ref } from 'vue';
import { useStore } from 'vuex';

// Components
import { BaseContainer, BaseColumn, BaseGrid } from '@/components/primitives';
import { BaseButton, BaseSpacer, BaseText } from '@/components/partials';
import { BaseForm, BaseInput, BaseError } from '@/components/partials/forms';
import { Modal } from '@/components/containers';
import { WopsProductInterface } from '@/classes/product';
import { StockCheckRequestInterface } from '@/store/types/product';
import { WopsProductVariantInterface } from '@/classes/productVariant';
import { HelperFormErrors, getFormError } from '@/helpers/form';
import { StockCheckForm } from '@/types/form';
import { useRoute } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'Index',
  setup(__props) {

const store = useStore();
const route = useRoute();

const stockCheckForm: StockCheckForm = {
  stock: null,
  errors: new HelperFormErrors(),
};

const getProduct: Promise<void> = async () => store.dispatch('product/getProduct', route.params.product_id);
const binName: ComputedRef<string> = computed(() => store.getters['bin/getSelectedBin']);
const product: ComputedRef<WopsProductInterface> = computed(() => store.state.product.product);
const productVariant: ComputedRef<WopsProductVariantInterface> = computed(
  () => store.getters['product/getProductVariant']
);

// @Todo: Change path to fetch errors from page store module
const errors: ComputedRef<Record<string, string[]>> = computed(() => store.getters['product/getStoreErrors']);

const stockCheckRequest: Ref<StockCheckRequestInterface> = computed(() => ({
  productVariantId: productVariant?.value?.id,
  binName: binName?.value,
  stock: stockCheckForm.stock,
}));
let confirmStockCheckModal: Ref<boolean> = ref(false);
const toggleConfirmModal: void = () => (confirmStockCheckModal.value = !confirmStockCheckModal.value);

const submitStockCheck: Promise<void> = async () => {
  await store.dispatch('product/stockCheck', stockCheckRequest.value);
};

onMounted(() => {
  if (!product.value?.id) getProduct();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(BaseContainer), null, {
    default: _withCtx(() => [
      _createVNode(_unref(BaseText), {
        tag: "h1",
        size: { default: '3xl' }
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode("Stock Check")
        ])),
        _: 1
      }),
      _createVNode(_unref(BaseSpacer), { size: "1" }),
      _createVNode(_unref(BaseText), { tag: "p" }, {
        default: _withCtx(() => [
          _cache[3] || (_cache[3] = _createTextVNode(" Please do a stock check for ")),
          _createVNode(_unref(BaseText), { tag: "span" }, {
            default: _withCtx(() => [
              _createElementVNode("b", null, _toDisplayString(product.value.name), 1)
            ]),
            _: 1
          }),
          _cache[4] || (_cache[4] = _createTextVNode(" in bin: ")),
          _createVNode(_unref(BaseText), { tag: "span" }, {
            default: _withCtx(() => [
              _createElementVNode("b", null, _toDisplayString(binName.value), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_unref(BaseSpacer), { size: "2" }),
      _createVNode(_unref(BaseText), { tag: "p" }, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [
          _createTextVNode("Total number of stock:")
        ])),
        _: 1
      }),
      _createVNode(_unref(BaseForm), { onFormSubmitted: toggleConfirmModal }, {
        default: _withCtx(() => [
          _createVNode(_unref(BaseGrid), null, {
            default: _withCtx(() => [
              _createVNode(_unref(BaseColumn), { span: { tablet: '6' } }, {
                default: _withCtx(() => [
                  _createVNode(_unref(BaseInput), {
                    class: "input-stock",
                    label: "Stock",
                    name: "stock",
                    type: "number",
                    value: stockCheckForm.stock,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((stockCheckForm.stock) = $event)),
                    "onReset:errors": _cache[1] || (_cache[1] = ($event: any) => (stockCheckForm.errors.clear('stock'))),
                    error: stockCheckForm.errors.get('stock')
                  }, null, 8, ["value", "error"]),
                  _createVNode(_unref(BaseSpacer))
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_unref(BaseSpacer)),
      _createVNode(_unref(Modal), {
        title: "Confirm stock check",
        "open-button-text": "Submit stock check",
        color: "default"
      }, {
        default: _withCtx(() => [
          (errors.value)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_unref(BaseError), null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(getFormError)(errors.value)), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_unref(BaseSpacer), { size: "1" })
              ], 64))
            : _createCommentVNode("", true),
          _createVNode(_unref(BaseForm), { onFormSubmitted: submitStockCheck }, {
            default: _withCtx(() => [
              _createVNode(_unref(BaseText), { tag: "p" }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode("Are you sure you want to submit this stock check?")
                ])),
                _: 1
              }),
              _createVNode(_unref(BaseSpacer), { size: "1" }),
              _createVNode(_unref(BaseButton), {
                class: "btn-confirm-stock-check",
                color: "success",
                type: "submit"
              }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createElementVNode("span", null, "Confirm", -1)
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})